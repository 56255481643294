

















































































































































import Vue from 'vue';
import CountTo from 'vue-count-to';
import * as echarts from 'echarts/core';
import * as api from '@/api/index';
import {
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  coccMtdsResource_user_liabilityExemptionFirst,
  coccMtdsResource_user_liabilityExemptionConfirm,
  agencies_benefitBaseInfo,
} from '@/api/index';
import { mapGetters } from 'vuex';
echarts.use([
  PieChart,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  CanvasRenderer,
]);

type platformData = {
  id?: string;
  name: string;
  field: string;
  value: string;
  bg?: string;
  icon?: string;
};

type platformDatas = {
  id: string;
  name: string;
  num: number;
  parentId: null;
  value?: number | undefined;
};

export default Vue.extend({
  name: 'index',
  components: {
    CountTo,
  },
  data() {
    return {
      disclaimersVisible: false,
      platformCountStlData: null,
      countCompanyData: null as any,
      aliyunAssetsUrl: `https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YZJ/`,
      platformDataList: [
        {
          name: '工程项目',
          field: 'constructCount',
          value: '',
        },
        {
          name: '单项工程',
          field: 'singleCount',
          value: '',
        },
        {
          name: '单位工程',
          field: 'unitCount',
          value: '',
        },
        {
          name: '分部工程',
          field: 'branchCount',
          value: '',
        },
        {
          name: '综合单价',
          field: 'billDeCount',
          value: '',
        },
        {
          name: '清单项',
          field: 'quantitiesCount',
          value: '',
        },
        {
          name: '定额项',
          field: 'deCount',
          value: '',
        },
        {
          name: '材料库',
          field: 'materialCount',
          value: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    '$route.query.id': {
      handler() {
        this.getUserLiabilityExemption();
        this.setPlatformIcon();
        this.getUserCountCompanyData();
        this.getPlatformCountStlData();
      },
      immediate: true,
    },
  },
  methods: {
    agreeChangeLiabilityExemption() {
      this.disclaimersVisible = false;
      coccMtdsResource_user_liabilityExemptionConfirm();
    },
    /**
     * @description: 获取用户是否免责声明
     * @param {*}
     * @return {*}
     */
    getUserLiabilityExemption() {
      coccMtdsResource_user_liabilityExemptionFirst().then((res) => {
        this.disclaimersVisible = !res.result;
      });
    },
    /**
     * @description: 获取个人数据
     * @param {*}
     * @return {*}
     */
    getUserCountCompanyData() {
      agencies_benefitBaseInfo().then((res: any) => {
        this.countCompanyData = res.result;
      });
    },
    dataIsnumber(val) {
      return !isNaN(+val);
    },
    /**
     * @description: 获取平台数据
     * @param {*}
     * @return {*}
     */
    getPlatformCountStlData() {
      api.coccMtdsResource_project_statistics().then((res: any) => {
        if (res.status === 200) {
          const data = res.result;
          const countData = data.countData;
          this.platformCountStlData = data;
          this.platformDataList = this.platformDataList.map((item) => {
            item.value = countData[item.field];
            return item;
          });
          const projectData = data.project.list;
          const standardData = data.standard.list;
          this.projectCasePieEchatr(projectData);
          this.compositePricePieEchatr(standardData);
        }
      });
    },
    /**
     * @description: 项目案例饼状图
     * @param {*}
     * @return {*}
     */
    projectCasePieEchatr(projectData: platformDatas[] = []) {
      const chartDom = document.getElementById(
        'project-case-echatr'
      ) as HTMLElement;
      const chart = echarts.init(chartDom);
      const data = projectData.map((item: platformDatas) => {
        item.value = item.num;
        return item;
      });
      const option = {
        title: {
          text: '项目案例库',
          left: '195px',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          top: '50%',
          left: '58%',
          height: '150px',
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            data,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
              },
              itemStyle: {},
            },
            center: ['250px', '50%'],
          },
        ],
      };
      chart.setOption(option);
    },
    compositePricePieEchatr(projectData: platformDatas[] = []) {
      const chartDom: HTMLElement = document.getElementById(
        'composite-price-echatr'
      ) as HTMLElement;
      const chart = echarts.init(chartDom);
      const data = projectData.map((item: platformDatas) => {
        item.value = item.num;
        return item;
      });
      const option = {
        title: {
          text: '综合单价库',
          left: '195px',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: 'vertical',
          top: '50%',
          left: '58%',
          height: '150px',
        },
        series: [
          {
            name: '',
            type: 'pie',
            data,
            emphasis: {
              label: {
                show: false,
                fontSize: '16',
              },
              itemStyle: {},
            },
            label: {
              show: false,
            },
            center: ['250px', '50%'],
          },
        ],
      };
      chart.setOption(option);
    },
    /**
     * @description: 设置平台数据icon
     * @param {*}
     * @return {*}
     */
    setPlatformIcon() {
      this.platformDataList = this.platformDataList.map(
        (item: platformData) => {
          item.icon = `${this.aliyunAssetsUrl}${item.field}.png`;
          item.bg = `${this.aliyunAssetsUrl}${item.field}-bg.png`;
          return item;
        }
      );
    },
  },
});
